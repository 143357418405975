import React, { useState, useEffect } from "react";
import Popover from "@material-ui/core/Popover";

function MyPopover({ component, anchorTarget }) {
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setAnchorEl(anchorTarget);
  }, [anchorTarget, component]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {component}
    </Popover>
  );
}

export default MyPopover;
