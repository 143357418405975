import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";

function Participants({ userEmail, participantList, setParticipantList }) {
  const [input, setInput] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      addParticipant();
    }
  };

  function displayErrorWarning(msg) {
    return;
  }
  function verifyEmail(email) {
    const regx =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    return email.toLowerCase().match(regx);
  }

  function verifySchoolEmail(email) {
    const regx =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*.edu$/;
    return email.toLowerCase().match(regx);
  }

  function addParticipant() {
    if (!verifyEmail(input)) {
      displayErrorWarning("Please enter a valid email");
      return;
    }

    if (!verifySchoolEmail(input)) {
      displayErrorWarning(
        "Only verified students are allowed on Pairstudy. Please enter a school email."
      );
      return;
    }
    if (input === userEmail) {
      displayErrorWarning("You cannot invite yourself to your own meeting :)");
      return;
    }
    participantList.push(input);
    setParticipantList(participantList);
    setInput("");
  }

  return (
    <>
      <label>Invite your friends</label>&nbsp;&nbsp;
      <input
        type="text"
        value={input}
        placeholder="Please enter their email"
        onChange={(event) => {
          setInput(event.target.value);
        }}
        onKeyDown={handleKeyDown}
      />
      &nbsp;
      <input type="button" value="Add" onClick={addParticipant} />
      {participantList.map((participant) => (
        <div>
          <span>{participant}</span>
          <div className="closeButton">
            <FaTimes
              color="black"
              size={14}
              onClick={() => {
                setParticipantList(
                  participantList.filter((email) => email !== participant)
                );
              }}
            />
          </div>
        </div>
      ))}
    </>
  );
}

export default Participants;
