import React, { useEffect, useState } from "react";
//https://github.com/uber/react-vis/blob/premodern/showcase/plot/clustered-stacked-bar-chart.js
// import "./App.css";
import "react-vis/dist/style.css";
import axios from "axios";
import "./WeeklyStats.css";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
} from "react-vis";

function WeeklyStats({ email, idToken }) {
  const [totalMinutes, setTotalMinutes] = useState(0);
  const [monday, setMonday] = useState(0);
  const [tuesday, setTuesday] = useState(0);
  const [wednesday, setWednesday] = useState(0);
  const [thursday, setThursday] = useState(0);
  const [friday, setFriday] = useState(0);
  const [saturday, setSaturday] = useState(0);
  const [sunday, setSunday] = useState(0);
  const [thisWeekTotal, setThisWeekTotal] = useState(0);

  useEffect(() => {
    // local user ID
    const getStats = async () => {
      const sendObj = {
        email,
        idToken,
      };

      let res = await axios.post("/api/users/getStats", sendObj);
      let data = res.data;
      // console.log("resss ", res.data);
      if (data.hasOwnProperty("totalMinutes")) {
        // console.log(data);
        setTotalMinutes(data["totalMinutes"]);
        setSunday(data["0"]);
        setMonday(data["1"]);
        setTuesday(data["2"]);
        setWednesday(data["3"]);
        setThursday(data["4"]);
        setFriday(data["5"]);
        setSaturday(data["6"]);

        let acc = 0;
        for (let i = 0; i < 7; i++) {
          acc += data[String(i)];
        }
        setThisWeekTotal(acc);
      }
    };

    getStats();
  }, [email]);

  const BarSeries = VerticalBarSeries;
  // const content = useCanvas ? "TOGGLE TO SVG" : "TOGGLE TO CANVAS";
  return (
    <div className="WeeklyStats">
      <XYPlot
        className="clustered-stacked-bar-chart-example"
        xType="ordinal"
        stackBy="y"
        width={250}
        height={250}
      >
        <DiscreteColorLegend
          style={{ position: "absolute", left: "200px", bottom: "245px" }}
          orientation="horizontal"
          items={[
            {
              title: "Hours",
              color: "#7dc693",
            },
          ]}
        />
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis />
        <YAxis />
        <BarSeries
          cluster="2015"
          color="#7dc693"
          data={[
            { x: "M", y: Number.parseFloat(monday / 60).toFixed(1) },
            { x: "TU", y: Number.parseFloat(tuesday / 60).toFixed(1) },
            { x: "W", y: Number.parseFloat(wednesday / 60).toFixed(1) },
            { x: "Th", y: Number.parseFloat(thursday / 60).toFixed(1) },
            { x: "F", y: Number.parseFloat(friday / 60).toFixed(1) },
            { x: "Sa", y: Number.parseFloat(saturday / 60).toFixed(1) },
            { x: "Su", y: Number.parseFloat(sunday / 60).toFixed(1) },
          ]}
        />
      </XYPlot>
      <div>
        <p className="statsInfo">{`This week: ${Number.parseFloat(
          thisWeekTotal / 60
        ).toFixed(1)} hrs`}</p>
        <p className="statsInfo">{`Total: ${Number.parseFloat(
          totalMinutes / 60
        ).toFixed(1)} hrs`}</p>
      </div>
    </div>
  );
}

export default WeeklyStats;
