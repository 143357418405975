import React, { useRef, useState, useEffect } from "react";
import { FaTimes, FaPlay, FaPause } from "react-icons/fa";
import Timer from "react-compound-timer";

function TodoItem({
  id,
  item,
  onCheck,
  onComplete,
  completed,
  time,
  updateTimer,
}) {
  const timerObjectRef = useRef();
  const [timerIcon, setTimerIcon] = useState("play");
  const [timerTime, setTimerTime] = useState(time);
  const iconColor = "a9cadf";

  useEffect(() => {
    // console.log("todo item");
    // console.log(id, item);
  });

  function getStyle() {
    return {
      textDecoration: completed ? "line-through" : "none",
      color: "#000000",
    };
  }

  return (
    <>
      <div className="eachItem">
        <input
          className="regular-checkbox"
          type="checkbox"
          defaultChecked={completed}
          onChange={() => {
            onComplete(id);
            timerObjectRef.current.stop();
            setTimerIcon("play");
            updateTimer(id, Math.floor(timerTime));
          }}
        />
        {/* <span className="checkmark"></span> */}
        <div className="todoTaskText" style={getStyle()}>
          <p>{item.substring(0, 23)} </p>
        </div>
        {timerIcon === "play" ? (
          <FaPlay
            className="icons"
            color={iconColor}
            size={12}
            onClick={() => {
              timerObjectRef.current.start();
              setTimerIcon("pause");
              updateTimer(id, Math.floor(timerTime));
            }}
          />
        ) : (
          <FaPause
            className="icons"
            color={iconColor}
            size={12}
            onClick={() => {
              timerObjectRef.current.stop();
              setTimerIcon("play");
              updateTimer(id, Math.floor(timerTime));
            }}
          />
        )}
        <div className="itemTimer">
          <Timer
            initialTime={timerTime}
            direction="forward"
            startImmediately={false}
          >
            {(control) => {
              timerObjectRef.current = control;

              return (
                <>
                  {setTimerTime(control.getTime())}
                  <Timer.Hours />:
                  <Timer.Minutes />:
                  <Timer.Seconds />
                </>
              );
            }}
          </Timer>
        </div>

        <div className="closeButton">
          <FaTimes
            color={iconColor}
            size={18}
            onClick={() => {
              onCheck(id);
            }}
          />
        </div>
      </div>
    </>
  );
}
export default TodoItem;
