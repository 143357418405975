import React, { useState, useEffect } from "react";
import axios from "axios";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import "./profile_card.css";
import CardProfile from "./cardProfile";
import "../../../Home/mobile.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Meeting({
  idToken,
  meetingObj,
  user,
  editMeeting,
  cancelMeeting,
  removeFromMeeting,
  feedMode,
  handleSignUp,
}) {
  const [expand, setExpand] = useState(false);
  const [meetingIsLive, setMeetingIsLive] = useState(sessionIsLive());
  const [iconVisibility, setIconVisibility] = useState(true);
  const [sessionOwner, setSessionOwner] = useState(user);
  const [userOwnsSession, setUserOwnsSession] = useState(false);
  const [userIsParticipant, setUserIsParticipant] = useState(false);
  const [participantInfo, setParticipantInfo] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRemoveParticipantDialog, setOpenRemoveParticipantDialog] =
    useState(false);
  const [openParticipantDialog, setOpenParticipantDialog] = useState(false);

  useEffect(() => {
    getMeetingOwner();
    getParticipantInfo();
    if (
      meetingObj.participants &&
      meetingObj.participants.find((el) => el === user.email) === user.email
    ) {
      setUserIsParticipant(true);
    } else {
      setUserIsParticipant(false);
    }
    setMeetingIsLive(sessionIsLive());
  }, [meetingObj]);

  const getMeetingOwner = async () => {
    if (meetingObj.sessionOwner === user.email) {
      setSessionOwner(user);
      setUserOwnsSession(true);
      return;
    }
    const res = await axios.post("/api/users/personalInfo", {
      idToken,
      userEmail: meetingObj.sessionOwner,
    });
    if (!res.data) return;
    setSessionOwner(res.data.user);
    setUserOwnsSession(false);
  };

  const getUserData = async () => {
    const res = await axios.post("/api/users/personalInfo", {
      idToken,
      userEmail: sessionOwner.email,
    });
    if (!res.data) return;
    return res.data;
  };

  const getParticipantInfo = () => {
    const newArr = [];
    if (meetingObj.participants) {
      meetingObj.participants.forEach(async (userEmail) => {
        const res = await axios.post("/api/users/personalInfo", {
          idToken,
          userEmail,
        });

        if (!res.data) return;
        newArr.push(res.data.user);
      });
    }
    setParticipantInfo(newArr);
  };

  const getParticipantCard = (participant) => {
    if (participant) {
      return (
        <div className="participant-card">
          <img
            width="40"
            height="40"
            className="profileImage"
            src={participant.imageUrl}
          />
          <div className="participant-card-text">
            <div>{participant.userName}</div>
            <div className="participant-email">{participant.email}</div>
          </div>
        </div>
      );
    }
    return null;
  };
  const handleRemoveParticipantDialog = () => {
    setOpenRemoveParticipantDialog(true);
  };
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleRemoveParticipantDialogClose = () => {
    setOpenRemoveParticipantDialog(false);
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setAnchorEl(null);
  };

  function handleCancelClick() {
    handleDialogOpen();
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverid = open ? "simple-popover" : undefined;

  const DescriptionTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      color: "#f5f5f9",
      maxWidth: 400,
      fontSize: theme.typography.pxToRem(12),
      // border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const UserProfileTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "transparent",
      color: "#f5f5f9",
      maxWidth: 400,
      fontSize: theme.typography.pxToRem(12),
      // border: "1px solid #dadde9",
    },
  }))(Tooltip);

  function calcTime(timeStamp) {
    const dateObj = new Date(timeStamp);
    const hour = dateObj.getHours();
    const minute = dateObj.getMinutes();
    const res = `${hour < 10 ? "0" + hour : hour}:${
      minute < 10 ? "0" + minute : minute
    }`;

    return res;
  }

  function getStyle() {
    return {
      visibility: iconVisibility ? "visible" : "hidden",
    };
  }

  function displayDate() {
    const dateObj = new Date(meetingObj.startTimeStamp);
    return dateObj.toDateString();
  }

  function sessionIsLive() {
    return meetingObj.startTimeStamp <= Date.now();
    // return true;
  }

  function getJoinButtonStyle() {
    if (!(meetingIsLive || sessionIsLive())) {
      return {
        color: "rgba(0, 0, 0, 0.26)",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
        cursor: "not-allowed",
        transform: "none",
        pointerEvents: "none",
      };
    }
    return {};
  }

  return (
    sessionOwner && (
      <>
        <div className="meeting-card">
          <div className="meeting-left-column">
            <UserProfileTooltip
              title={
                <CardProfile
                  userName={sessionOwner.userName}
                  imageUrl={sessionOwner.imageUrl}
                  school={sessionOwner.school}
                  major={sessionOwner.major}
                  gradYear={sessionOwner.graduationYear}
                />
              }
              placement="top"
            >
              <img
                width="40"
                height="40"
                className="profileImage"
                src={sessionOwner.imageUrl}
                // onMouseEnter={()=>console.log("mouseEnter")}
                // onMouseLeave={()=>console.log("mouseLeave")}
              />
            </UserProfileTooltip>

            <DescriptionTooltip
              title={
                meetingObj.description ? (
                  <span className="description-text">
                    {meetingObj.description}
                  </span>
                ) : (
                  ""
                )
              }
              placement="bottom"
            >
              <div className="meetingTitle">
                <div className="meeting-title-container">
                  <h2 className="meeting-title-text classTitle">
                    {meetingObj.title}&nbsp;&nbsp;
                    {meetingIsLive && <span className="live-div">LIVE</span>}
                  </h2>
                </div>
                {meetingObj.description && (
                  <span className="meeting-description">
                    {meetingObj.description}
                  </span>
                )}
              </div>
            </DescriptionTooltip>
          </div>
          <div className="meeting-right-column">
            <div className="meeting-date classDescription">
              <div>{displayDate()}</div>
              <div>
                {calcTime(meetingObj.startTimeStamp)}
                &nbsp;&mdash;&nbsp;
                {calcTime(meetingObj.endTimeStamp)}
              </div>
            </div>
            <div className="meeting-options-div">
              {userOwnsSession || userIsParticipant || meetingIsLive ? (
                <div>
                  <Link
                    to={{
                      pathname: "/virtualRoom",
                      state: {
                        roomName: meetingObj.title,
                        roomID: meetingObj.id,
                      },
                    }}
                    style={getJoinButtonStyle()}
                    className="classJoinButton cardButton joinPersonalRoomButton"
                  >
                    <span>Join</span>
                  </Link>
                </div>
              ) : (
                <div>
                  <div
                    className="classJoinButton cardButton joinPersonalRoomButton"
                    style={{ backgroundColor: "#dbecf7" }}
                    onClick={() => handleSignUp(meetingObj.id)}
                  >
                    <span>Sign up</span>
                  </div>
                </div>
              )}
              <IconButton
                aria-controls="options-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <Menu
          id="options-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem
            onClick={() => {
              setOpenParticipantDialog(true);
              handleClose();
            }}
          >
            View Participants
          </MenuItem>

          {userOwnsSession && !feedMode && (
            <MenuItem
              onClick={() => {
                handleClose();
                editMeeting(meetingObj.id);
              }}
            >
              Edit Session
            </MenuItem>
          )}
          {userOwnsSession && !feedMode && (
            <MenuItem
              onClick={() => {
                handleClose();
                handleCancelClick();
              }}
            >
              Cancel Session
            </MenuItem>
          )}
          {userIsParticipant && !feedMode && (
            <MenuItem
              onClick={() => {
                handleClose();
                handleRemoveParticipantDialog();
              }}
            >
              Can't make it
            </MenuItem>
          )}
        </Menu>
        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Are you sure you want to cancel?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              You will not be able to undo this change.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                cancelMeeting(meetingObj.id);
                handleDialogClose();
              }}
              color="primary"
            >
              Yes, cancel
            </Button>
            <Button onClick={handleDialogClose} color="primary">
              Go back
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openRemoveParticipantDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleRemoveParticipantDialogClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Are you sure you want to cancel?"}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                removeFromMeeting(meetingObj.id);
                handleRemoveParticipantDialogClose();
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button
              onClick={handleRemoveParticipantDialogClose}
              color="primary"
            >
              Go back
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openParticipantDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenParticipantDialog(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="participants">
            <div className="participants-title">Participants</div>
            <div>
              <div className="participants-list">
                <div className="participant-card">
                  <img
                    width="40"
                    height="40"
                    className="profileImage"
                    src={sessionOwner.imageUrl}
                  />
                  <div className="participant-card-text">
                    <div>
                      {sessionOwner.userName}&nbsp;
                      <span style={{ fontWeight: 600 }}>(Owner)</span>
                    </div>
                    <div className="participant-email">
                      {sessionOwner.email}
                    </div>
                  </div>
                </div>
                {participantInfo.map((participant) =>
                  getParticipantCard(participant)
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </>
    )
  );
}

export default Meeting;
