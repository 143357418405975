import React from "react";
import "./profile_card.css";

function CardProfile({ imageUrl, userName, school, major, gradYear }) {
  return (
    <div class="card">
      <img src={imageUrl} alt="profile_img" />
      <div class="bold chip profile-name">
        {/* <span className="bold"></span> */}
        {userName}
      </div>
      {school && (
        <div class="chip">
          <span>School: </span>
          {school}
        </div>
      )}
      {major && (
        <div class="chip">
          <span>Major: </span>
          {major}
        </div>
      )}
      {gradYear && (
        <div class="chip">
          <span>Grad Year: </span>
          {gradYear}
        </div>
      )}
    </div>
  );
}

export default CardProfile;
