import React, { useEffect, useState } from "react";
import libraryImage from "./assets/library.svg";
import cafeImage from "./assets/cafe.svg";
import Meetings from "./components/Meetings";
import RoomDetails from "./components/RoomDetails.js";
import Modal from "../components/ModalHomePage";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

function HomeTab({ idToken, userName, email, imageUrl }) {
  const pairstudyMeeting = new Set([
    "who2103@columbia.edu",
    "bnb2122@columbia.edu",
    "hl3169@columbia.edu",
    "sbacelic21@gsb.columbia.edu",
  ]);
  const [major, setMajor] = useState("");
  const [school, setSchool] = useState("");
  const [gradYear, setGradYear] = useState("");

  useEffect(() => {
    const getPersonalInfo = async () => {
      const res = await axios.post("/api/users/personalInfo", {
        idToken,
        userEmail: email,
      });
      const userData = res.data.user;
      if (userData) {
        setMajor(userData.major);
        setSchool(userData.school);
        setGradYear(userData.graduationYear);
      }
    };
    setTimeout(getPersonalInfo, 500);
  }, []);
  return (
    <>
      <h1 className="mainRoomTitle">Welcome back, {userName.split(" ")[0]}.</h1>
      <div className="topRow">
        <div className="groupSection" id="groupSectionFirst">
          <div className="roomCardTitle">Library</div>
          <h6 className="libraryDescription">
            Work quietly with others in our library room!
          </h6>
          <img alt="library" className="roomImage" src={libraryImage}></img>

          <Link
            to={{
              pathname: "/virtualRoom",
              state: {
                roomName: "Virtual Library",
                roomID: "VirtualLibrary",
              },
            }}
          >
            <div className="cardButton joinPersonalRoomButton">
              <span>Join library</span>
            </div>
          </Link>
        </div>

        <div className="groupSection" id="cafeGroupSection">
          <div className="roomCardTitle">Cafe</div>
          <h6 className="libraryDescription">
            Socialize and chat with others in our cafe room!
          </h6>
          <img alt="cafe" className="roomImage" src={cafeImage}></img>
          <div className="publicRoom">
            <Link
              to={{
                pathname: "/virtualRoom",
                state: {
                  roomName: "Virtual Cafe",
                  roomID: "VirtualCafe",
                },
              }}
            >
              <div className="mainRoomButton cardButton joinPersonalRoomButton">
                <span>Join cafe</span>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="bottomRow">
        {pairstudyMeeting.has(email) ? (
          <div className="bottomRowSection">
            <div className="classes">
              <h2 className="roomCardTitle bottomRowTitle">Meetings</h2>
              <div className="classSection">
                <h2 className="classTitle">Pairstudy Meeting</h2>
                {/* <RoomDetails idToken={idToken} roomName="pairstudy/1004" /> */}
                <div className="classDescription"></div>
                <Link
                  to={{
                    pathname: "/virtualRoom",
                    state: {
                      roomName: "Virtual Room",
                      roomID: "1004",
                    },
                  }}
                >
                  <div className="classJoinButton cardButton joinPersonalRoomButton">
                    <span>Join</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <Meetings
        idToken={idToken}
        user={{ userName, email, imageUrl, school, major, gradYear }}
      />
    </>
  );
}

export default HomeTab;
