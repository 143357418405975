import React from "react";
import logoPNG from "../../assets/psLogo.png";
import "./Loading.css";

function Loading() {
  return (
    <div className="loading-div">
      <img
        src={logoPNG}
        width="70"
        height="60"
        className="navbar-logo"
        alt="logo"
      />
    </div>
  );
}

export default Loading;
