import React, { useState, useEffect } from "react";
import { Jutsu } from "react-jutsu";
import { confirmAlert } from "react-confirm-alert";
import { useHistory, useLocation } from "react-router-dom";
import { Beforeunload } from "react-beforeunload";
import { useBeforeunload } from "react-beforeunload";
import "./GroupRoom.css";
import axios from "axios";
import logoPNG from "../../assets/psLogo.png";
import TodoApp from "../components/TodoApp";
import Loading from "../components/Loading";

const GroupRoom = ({ props, idToken, signedUser }) => {
  // routing
  const history = useHistory();
  const reRouteToHome = () => history.push("/home");
  const { state } = useLocation();

  const [room, setRoom] = useState(
    // process.env.NODE_ENV === "development"
    //   ? `pairstudy/virtualLibraryDev`
    //   : `pairstudy/${state.roomID}`
    `pairstudy/${state.roomID}`
  );

  const [name, setName] = useState("");
  const [startJitsi, setStartJitsi] = useState(false);
  const [password, setPassword] = useState("");
  var email, uName;
  try {
    uName = signedUser.displayName;
    email = signedUser.email;
  } catch (err) {
    reRouteToHome();
  }

  const [configOverwrite, setConfigOverwrite] = useState({
    subject: state.roomName,
    prejoinPageEnabled: true,
    enableInsecureRoomNameWarning: false,
    startWithAudioMuted: true,
    startWithVideoMuted: true,
  });

  const [interfaceConfigOverwrite, setInterfaceConfigOverwrite] = useState({
    DEFAULT_BACKGROUND: "#FFFFFF",
    SHOW_CHROME_EXTENSION_BANNER: false,
  });

  const handleCloseTab = () => {
    const sendObj = {
      roomPath: room,
      userName: uName,
      idToken: idToken,
    };

    //end jitsi meeting for user
    axios.post("/endJitsiSession", sendObj);

    // If user decides to stay on page, this will trigger after 2mins
    setTimeout(function () {
      axios.post("/joinJitsi", sendObj);
    }, 120000);
  };

  const handleEndMeeting = () => {
    const sendObj = {
      userEmail: email,
      roomPath: room,
      userName: uName,
      idToken: idToken,
    };

    //end jitsi meeting for user
    axios.post("/endJitsiSession", sendObj);

    reRouteToHome();
  };

  useEffect(() => {
    setRoom(`pairstudy/${state.roomID}`);
    joinJitsiRoom();
    return function cleanup() {
      handleEndMeeting();
    };
  }, []);

  const joinJitsiRoom = () => {
    //data to send to backend
    const sendObj = {
      userEmail: email,
      userName: uName,
      roomPath: room,
      idToken: idToken,
    };

    //join jitsi room
    axios.post("/joinJitsi", sendObj).then((res) => {
      const roomPassword = res.data;
      setPassword(roomPassword);
      setStartJitsi(true);
    });
  };

  const beforeUnloadHandler = () => {
    confirmAlert({
      title: "Exit room",
      message: "Are you sure you want to exit the room?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleCloseTab();
            window.close();
          },
        },
        {
          label: "No",
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  useBeforeunload((event) => {
    event.preventDefault();
    // beforeUnloadHandler();
    handleCloseTab();
    return undefined;
  });

  return (
    <div className="virtualLibraryWrap">
      <div className="topBar">
        <img
          id="psLogo"
          alt="pairstudy logo"
          className="smallLogo"
          onClick={handleEndMeeting}
          src={logoPNG}
        />
      </div>
      <TodoApp email={email} idToken={idToken}></TodoApp>
      <div className="jitsiWrap">
        {startJitsi ? (
          <Jutsu
            roomName={room}
            password={password}
            displayName={uName}
            onMeetingEnd={handleEndMeeting}
            loadingComponent={<Loading />}
            configOverwrite={configOverwrite}
            interfaceConfigOverwrite={interfaceConfigOverwrite}
            containerStyles={{ width: "100%", height: "95vh" }}
          />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

// doc: https://github.com/this-fifo/jutsu
// jitsi: https://github.com/jitsi/jitsi-meet
export default GroupRoom;
