import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../App.scss";
import "./Home.css";
import "./mobile.css";
import Navbar from "./components/Navbar/Navbar.js";
import HomeTab from "./Home";
import Modal from "../components/ModalHomePage";
import Dialog from "@material-ui/core/Dialog";
import TodoApp from "../components/TodoApp";
import WeeklyStats from "../components/Stats/WeeklyStats";
import Loading from "../components/Loading";

// import { useHistory } from "react-router-dom";
// import axios from "axios";
import { FirebaseContext } from "../../auth/Firebase";

const Home = ({ idToken, signedUser }) => {
  const email = signedUser.email;
  const userName = signedUser.displayName;
  const imageUrl = signedUser.photoURL;
  const [isFormCompleted, setIsFormCompleted] = useState(true);
  const [iconSelected, setIconSelected] = useState("home");
  const [userHasAccess, setUserHasAccess] = useState(null);

  // const history = useHistory();
  const allowedEmails = new Set([
    "osegharewilliam@gmail.com",
    "brownbennet@gmail.com",
    "sbacelic22@gmail.com",
  ]);

  useEffect(() => {
    // parse email to school
    const domain = email.split("@");
    const subDomain = domain["1"].split(".");
    // setUsersUniversity(subDomain[subDomain.length - 2]);

    const domainSplitByDot = email.split(".");
    const domainEndingStub = domainSplitByDot[domainSplitByDot.length - 1];

    // local user ID
    const getFormStatus = async () => {
      const res = await axios.post("/api/users/getFormStatus", {
        idToken,
      });
      setIsFormCompleted(res.data.status);
    };

    setTimeout(getFormStatus, 100);
    // setTimeout(getPersonalId, 800);

    setUserHasAccess(
      domainEndingStub === "edu" || allowedEmails.has(email) ? true : false
    );
  }, [email, imageUrl, userName]);

  const handleDialogClose = () => {
    setIsFormCompleted(true);
  };

  if (userHasAccess) {
    return (
      <div className="top-home-container">
        <Navbar
          email={email}
          imageUrl={imageUrl}
          setIconSelected={setIconSelected}
          iconSelected={iconSelected}
        />
        <div className="homeContainer">
          <div className="leftColumn">
            <div className="todoContainer joinRoomCard">
              <TodoApp idToken={idToken}></TodoApp>
            </div>
            <div className="statsCard joinRoomCard ">
              <h3 className="roomCardTitle">Your stats</h3>
              <WeeklyStats email={email} idToken={idToken}></WeeklyStats>
            </div>
          </div>
          {/* <Dialog
            open={!isFormCompleted}
            keepMounted
            className="modalDialog"
            maxWidth="100vw"
            onClose={handleDialogClose}
            disableBackdropClick="true"
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            disableEscapeKeyDown="true"
          >
            <Modal
              email={email}
              idToken={idToken}
              name={userName}
              setIsFormCompleted={setIsFormCompleted}
            />
          </Dialog> */}
          <div id="room1" className="rightColumn roomCard">
            <HomeTab
              idToken={idToken}
              userName={userName}
              email={email}
              imageUrl={imageUrl}
            />
            <a
              id="feedbackLink"
              href="https://bit.ly/3ecnizv"
              target="_blank"
              rel="noopener noreferrer"
            >
              Interested in making PairStudy better?&nbsp; Give us some feedback
              here →
            </a>
          </div>
        </div>
      </div>
    );
  } else if (userHasAccess === null) {
    return <Loading />;
  } else {
    return (
      <div className="warningLoginPage">
        <h2>Please sign in with your school email</h2>
        <FirebaseContext.Consumer>
          {(firebase) => {
            return (
              <div
                className="signOutButton"
                onClick={() => firebase.doSignOut()}
              >
                Sign out
              </div>
            );
          }}
        </FirebaseContext.Consumer>
      </div>
    );
  }
};

export default Home;
