import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../App.scss";
import "./Login.css";
import backArrow from "../../assets/backArrow.png";
import msPictogram from "../../assets/ms-pictogram.svg";
import googleIcon from "../../assets/googleLogo.png";
import { FirebaseContext } from "../../auth/Firebase";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

const Login = ({ signInSignal, closeModal }) => {
  const [isChrome, setIsChrome] = useState(true);
  useEffect(() => {
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== "undefined";
    var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
    var isIOSChrome = winNav.userAgent.match("CriOS");
    var isFirefox = winNav.userAgent.toLowerCase().indexOf("firefox") > -1;

    if (isIOSChrome || isFirefox) {
      // is Google Chrome on IOS
      //setIsChrome(true);
      console.log("chrome on ios");
    } else if (
      isChromium !== null &&
      typeof isChromium !== "undefined" &&
      vendorName === "Google Inc." &&
      isOpera === false &&
      isIEedge === false
    ) {
      // is Google Chrome
      // console.log("is google chrome");
      setIsChrome(true);
    } else {
      // not Google Chrome
      setIsChrome(false);
      // alert('is not chrome')
    }

    // window.gapi.load("signin2", () => {
    //   window.gapi.signin2.render("login-button");
    // });
  }, []);

  function isChromeHtml() {
    return (
      <div className="loginPage">
        <div id="back">
          <CloseRoundedIcon onClick={closeModal} />
        </div>
        <div className="login-title">Log in to Pairstudy&#128640;</div>
        <div className="loginBox">
          <FirebaseContext.Consumer>
            {(firebase) => {
              return (
                <div
                  className="bsk-container"
                  onClick={() => firebase.doGoogleSignIn(signInSignal)}
                >
                  <img width="25" height="25" alt="profile" src={googleIcon} />
                  <div
                    style={{ fontWeight: "700" }}
                    className="bsk-btn bsk-btn-default"
                  >
                    {"Continue with Google"}
                  </div>
                </div>
              );
            }}
          </FirebaseContext.Consumer>
          <FirebaseContext.Consumer>
            {(firebase) => {
              return (
                <div
                  className="bsk-container"
                  onClick={() => firebase.doMsSignIn(signInSignal)}
                >
                  <img width="25" height="25" alt="profile" src={msPictogram} />
                  <div
                    style={{ fontWeight: "700" }}
                    className="bsk-btn bsk-btn-default"
                  >
                    {"\tContinue with Microsoft"}
                  </div>
                </div>
              );
            }}
          </FirebaseContext.Consumer>

          <p className="signPrompt">
            {" "}
            Please use your<br></br>university email{" "}
          </p>
        </div>
      </div>
    );
  }

  function isNotChromeHtml() {
    return (
      <div className="loginPage">
        <h3>Pairstudy only runs on Google Chrome</h3>
        <h1>:'(</h1>
        <a href="https://www.google.com/chrome/">Install google Chrome</a>
      </div>
    );
  }

  return (
    <div className="container">
      {isChrome ? isChromeHtml() : isNotChromeHtml()}
      {/* <p className="messageSigning">
        For students who use VPN, use VPN providers that protect you from{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.expressvpn.com/webrtc-leak-test"
        >
          {" "}
          webRTC leaks{" "}
        </a>
      </p> */}
    </div>
  );
};

export default Login;
