import React, { useRef, useEffect, useState } from "react";
import io from "socket.io-client";
import axios from "axios";
import Timer from "react-compound-timer";
import { useHistory } from "react-router-dom";
import { Beforeunload } from "react-beforeunload";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./Room.css";
import "../components/Todo.css";
import logoPNG from "../../assets/psLogo.png";
import TodoApp from "../components/TodoApp";
import Modal from "../components/Modal";
import Chat from "../components/Chat";

const Room = ({props, idToken, signedUser}) => {
  const userVideo = useRef();
  const senders = useRef([]);
  const partnerVideo = useRef();
  const peerRef = useRef();
  const socketRef = useRef();
  const otherUser = useRef();
  const userStream = useRef();
  const [localUserRoomID, setLocalUserRoomID] = useState("");
  const [showGoHomeOrRejoinHTML, setShowGoHomeOrRejoinHTML] = useState(false);
  const [isPomodoroStarted, setIsPomodoroStarted] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayPanda, setDisplayPanda] = useState(false);
  const [foundMatch, setFoundMatch] = useState(false);

  //chat
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState([]);

  const timerObjectRef = useRef();
  const audio = useRef(true);
  const video = useRef(true);
  const history = useHistory();
  const reRouteToHome = () => history.push("/home");
  const reRouteToMediaError = () => history.push("/mediaError");
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);

  // const handleGoHome = useCallback(() => history.push('/home',[history]))
  // var signedUser, idToken;

  var email;
  var roomNumber;
  var typeOfRoom;

  try {
    email = signedUser.email;
    roomNumber = props.match.params.number;
    typeOfRoom = props.match.params.roomType;
  } catch (err) {
    console.log(err);
    reRouteToHome();
  }

  useEffect(() => {
    joinASession();

    return () => {
      if (history.action === "POP") {
        disconnectFromSession();
        //history.replace("/");
      }
    };
  }, [history]);

  function modalClick() {
    setDisplayModal((prevData) => {
      return !prevData;
    });
  }

  function pandaClick() {
    setDisplayPanda((prevData) => {
      return !prevData;
    });
  }

  function joinASession() {
    // console.log("called joinASession")
    //get user media(audio and video)
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: { width: 1280, height: 720 } })
      .then((stream) => {
        userVideo.current.srcObject = stream;

        if (userVideo == null) {
          reRouteToMediaError();
          //alert("no video");
        }

        userStream.current = stream;
        // userStream.element.muted = true;

        //create socket to send data to backend
        socketRef.current = io.connect("/");

        //data to send to backend
        let sendObj = {
          userEmail: email,
          roomNumber: roomNumber,
          typeOfRoom: typeOfRoom,
          idToken : idToken
        };

        //send data to backend to create room
        axios.post("/number", sendObj).then((res) => {
          setFoundMatch(false);
          disconnectPip();
          const roomID = res.data;
          //console.log(res)
          setLocalUserRoomID(roomID);
          socketRef.current.emit("join room", roomID);
          socketRef.current.on("remoteClient_playTime", handleRemotePlayTime);
          socketRef.current.on("remoteClient_stopTime", handleRemoteStopTime);
          socketRef.current.on(
            "remoteClient_endOfSession",
            handleRemoteEndOfSession
          );

          socketRef.current.on("other user", (userID) => {
            callUser(userID);
            otherUser.current = userID;
          });

          socketRef.current.on("user joined", (userID) => {
            otherUser.current = userID;
          });

          socketRef.current.on("offer", handleRecieveCall);

          socketRef.current.on("answer", handleAnswer);

          socketRef.current.on("ice-candidate", handleNewICECandidateMsg);

          //chat
          socketRef.current.on("newChatMessage", (message) => {
            const incomingMessage = {
              ...message,
              ownedByCurrentUser: message.senderId === socketRef.current.id,
            };
            setMessages((messages) => [...messages, incomingMessage]);
          });
        });
      })
      .catch(function (err) {
        if (err.name === "NotAllowedError") {
          reRouteToMediaError();
        } else {
          reRouteToHome();
        }
      });
  }

  function shareScreen(){
    //console.log("called")
    navigator.mediaDevices.getDisplayMedia({
      video: {cursor: true},
      audio: {
        echoCancellation:true,
        noiseSuppression:true}
      }).then(screen_stream =>{
        //console.log('screen_stream',screen_stream.getTracks())
      const screenTrack = screen_stream.getTracks()[0];
      // console.log('screenTrack',screenTrack)
      // console.log('senders',senders)
      senders.current.find(sender => sender.track.kind === screenTrack.kind).replaceTrack(screenTrack);
      // console.log('senders after replacment',senders)
      var sharebtn = document.getElementById("share_scrn")
      sharebtn.innerHTML = "stop_screen_share"
      sharebtn.style.backgroundColor = "red"

      screenTrack.onended = function() {
      senders.current.find(sender => sender.track.kind === screenTrack.kind).replaceTrack(userStream.current.getTracks()[1]);
      sharebtn.innerHTML = "screen_share";
      // document.getElementById('localVideo').setAttribute("controls","false");


      // console.log("On ended",senders)
      }
    }).catch((err) => {
      console.log("Unable to get display, Error is:  " + err)
      //reRouteToMediaError();
    })
  }

  function disconnectFromSession() {
    socketRef.current.disconnect();
    // let the remoteUserKnow that I left
    tellServerLocalClientEndOfSession();
    // axios.post()
    // let the server know that local left
    if (typeOfRoom === "join" || typeOfRoom === "host") {
      axios.post("/api/users/endSession", {
        email: email,
        roomNumber: roomNumber,
        idToken: idToken
      });
    }
  }

  function callUser(userID) {
    peerRef.current = createPeer(userID);
    userStream.current
      .getTracks()
      .forEach(track => senders.current.push(peerRef.current.addTrack(track, userStream.current)));
  }

  function createPeer(userID) {
    const peer = new RTCPeerConnection({
      iceServers: [
        {
          urls: "stun:stun.stunprotocol.org",
        },
        {
          urls: "turn:numb.viagenie.ca",
          credential: "muazkh",
          username: "webrtc@live.com",
        },
      ],
    });

    peer.onicecandidate = handleICECandidateEvent;
    peer.ontrack = handleTrackEvent;
    peer.onnegotiationneeded = () => handleNegotiationNeededEvent(userID);

    handleClientPlayTime();
    setFoundMatch(true);
    return peer;
  }

  function handleNegotiationNeededEvent(userID) {
    peerRef.current
      .createOffer()
      .then((offer) => {
        return peerRef.current.setLocalDescription(offer);
      })
      .then(() => {
        const payload = {
          target: userID,
          caller: socketRef.current.id,
          sdp: peerRef.current.localDescription,
        };
        socketRef.current.emit("offer", payload);
      })
      .catch((e) => console.log(e));
  }

  function handleRecieveCall(incoming) {
    peerRef.current = createPeer();
    const desc = new RTCSessionDescription(incoming.sdp);
    peerRef.current
      .setRemoteDescription(desc)
      .then(() => {
        userStream.current
          .getTracks()
          .forEach((track) =>
          senders.current.push(peerRef.current.addTrack(track, userStream.current))
          );
      })
      .then(() => {
        return peerRef.current.createAnswer();
      })
      .then((answer) => {
        return peerRef.current.setLocalDescription(answer);
      })
      .then(() => {
        const payload = {
          target: incoming.caller,
          caller: socketRef.current.id,
          sdp: peerRef.current.localDescription,
        };
        socketRef.current.emit("answer", payload);
      });
  }

  function handleAnswer(message) {
    const desc = new RTCSessionDescription(message.sdp);
    peerRef.current.setRemoteDescription(desc).catch((e) => console.log(e));
    setDisplayModal((prevData) => {
      return false;
    });
  }

  function handleICECandidateEvent(e) {
    if (e.candidate) {
      const payload = {
        target: otherUser.current,
        candidate: e.candidate,
      };
      socketRef.current.emit("ice-candidate", payload);
    }
  }

  function handleNewICECandidateMsg(incoming) {
    const candidate = new RTCIceCandidate(incoming);

    peerRef.current.addIceCandidate(candidate).catch((e) => console.log(e));
  }

  function handleTrackEvent(e) {
    partnerVideo.current.srcObject = e.streams[0];
  }

  // chat functions start here
  const handleSendMessage = () => {
    sendMessage(newMessage);
    // console.log(messages)
    setMessages((messages) => [
      ...messages,
      {
        body: newMessage,
        ownedByCurrentUser: true,
        senderId: socketRef.current.id,
      },
    ]);
    setNewMessage("");
  };

  const sendMessage = (messageBody) => {
    socketRef.current.emit("newChatMessage", {
      body: messageBody,
      senderId: socketRef.current.id,
    });
  };

  // chat functions ends here

  // Pomodoro play funtions
  function handleRemotePlayTime() {
    // console.log("remote user clicked on play time ")
    // setPomodoroPlayHTML("continue");
    setIsPomodoroStarted(true);

    setIsPlayingVideo(false);
    setTimeout(() => {
      setDisplayModal(false);
    }, 1000);
    timerObjectRef.current.start();
  }

  function handleClientPlayTime() {
    // console.log("local user clicked on play time ")
    timerObjectRef.current.start();
    setIsPomodoroStarted(true);
    // setPomodoroPlayHTML("continue");
    setIsPlayingVideo(false);
    setTimeout(() => {
      setDisplayModal(false);
    }, 1000);

    socketRef.current.emit("localClient_playTime", localUserRoomID);
  }

  // function handleClientStopTime() {
  //   // console.log("local user clicked on stop time ")
  //   setIsPomodoroStarted(false);
  //   socketRef.current.emit("localClient_stopTime", localUserRoomID);
  //   timerObjectRef.current.stop();
  // }

  function handleRemoteStopTime() {
    // console.log("remote user clicked on stop time ")
    setIsPomodoroStarted(false);
    setFoundMatch(false);
    disconnectPip();
    timerObjectRef.current.stop();
  }

  function studyTime() {
    return (
      <Timer
        initialTime={0}
        // direction="backward"
        direction="forward"
        startImmediately={false}
        // onStart={() => console.log("call back on start")}
      >
        {(control) => {
          timerObjectRef.current = control;

          return (
            <React.Fragment>
              <div className="timeDisplay">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.youtube.com/watch?v=TgH9KXEQ0YU"
                >
                  {" "}
                  <span role="img" aria-labelledby="time">
                    {" 🕰 Time spent studying 📖 "}
                  </span>
                </a>
                <Timer.Minutes /> minutes {"  "}
                <Timer.Seconds /> seconds{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.bookculture.com/book/9780008347963"
                >
                  {" "}
                  <span role="img" aria-labelledby="otter">
                    {" 🦦🦦 "}{" "}
                  </span>
                </a>
              </div>

              {/* <Button onClick={handleClientStopTime}>pause studying</Button>
                <Button className="studyButton" onClick={handleClientPlayTime}>{`${pomodoroPlayHTML} studying`}</Button>} */}
            </React.Fragment>
          );
        }}
      </Timer>
    );
  }

  function muteAudio() {
    // console.log("Mute clicked");
    //console.log(document.getElementById("mute").innerHTML);
    audio.current = !audio.current;
    userStream.current.getAudioTracks()[0].enabled = audio.current;
    var mutebtn = document.getElementById("mutebtn");
    // if (mutebtn.innerHTML === "Mute") mutebtn.innerHTML = "UnMute";
    // else {
    //   mutebtn.innerHTML = "Mute";
    // }
    if (audio.current === false) mutebtn.innerHTML = "mic_off"
    else {
      mutebtn.innerHTML="mic"
    }
  }

  function muteVideo() {
    //console.log(document.getElementById("mute_video").innerHTML);
    video.current = !video.current;
    userStream.current.getVideoTracks()[0].enabled = video.current;
    var muteVideoBtn = document.getElementById("video_mute");
    // if (muteVideoBtn.innerHTML === "Mute Video") muteVideoBtn.innerHTML = "UnMute Video";
    // else {
    //   muteVideoBtn.innerHTML = "Mute Video";
    // }
    if (video.current === false) muteVideoBtn.innerHTML = "videocam_off"
    else {
      muteVideoBtn.innerHTML = "videocam"
    }
   }

   function fullScreen(){
    var vid = document.getElementById("remoteVideo")
    if(vid.requestFullScreen){
      vid.requestFullScreen();
    } else if(vid.webkitRequestFullScreen){
      vid.webkitRequestFullScreen();
    } else if(vid.mozRequestFullScreen){
      vid.mozRequestFullScreen();
    }
   }
  // function videoControl(){
  //   return (
  //     <div>
  //       <div className="localButton" id="mute_video" onClick={muteVideo}>
  //         Mute Video
  //       </div>
  //     </div>
  //   );
  // }
  // function audioControl() {
  //   return (
  //     <div>
  //       <div className="localButton" id="mute" onClick={muteAudio}>
  //         Mute
  //       </div>
  //     </div>
  //   );
  // }
 

  // function screenShare(){
  //   return (
  //     <div>
  //       <div className="localButton" id="share_screen" onClick={shareScreen}>
  //         Share Screen
  //       </div>
  //     </div>
  //   );
  // }

  function handleRemoteEndOfSession() {
    // give local user 2 option: go home or re-match
    peerRef.current.close();
    socketRef.current.disconnect();
    setShowGoHomeOrRejoinHTML(true);
    // console.log("remote user disconnected")
  }

  // function pauseYoutubeVideo(event) {
  //   console.log("pauseYoutubeVideo room");
  //   event.target.pauseVideo();
  // }

  function handleRejoinSession() {
    setIsPomodoroStarted(false);
    // setPomodoroPlayHTML("Start");
    setShowGoHomeOrRejoinHTML(false);
    socketRef.current.disconnect();
    joinASession();
  }

  function retunHomeOrRematch() {
    return (
      <div>
        {/* <div className="localButton" onClick={endOfSessionButton}  > {`Home  `} </div>{`  `} */}
        {typeOfRoom !== "join" ? (
          <div className="localButtonJoin" onClick={handleRejoinSession}>
            join another session
          </div>
        ) : (
          <div className="localButtonJoin" onClick={endOfSessionButton}>
            Home
          </div>
        )}
      </div>
    );
  }

  function tellServerLocalClientEndOfSession() {
    userStream.current.getTracks().forEach((track) => track.stop());
    socketRef.current.emit("localClient_endOfSession", localUserRoomID);
  }

  function endOfSessionButton() {
    confirmAlert({
      title: "Exit room",
      message: "Are you sure you want to exit the room?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setFoundMatch(false);
            disconnectPip();
            disconnectFromSession();
            reRouteToHome();
          },
        },
        {
          label: "No",
          onClick: () => {
            return;
          },
        },
      ],
    });
  }

  function endSession() {
    return (
      <div className="localButton" id="endSession" onClick={endOfSessionButton}>
        end session
      </div>
    );
  }
  function disconnectPip() {
    let video = document.getElementById("remoteVideo");
    if (video === document.pictureInPictureElement)
      document.exitPictureInPicture();
  }
  function handlePip() {
    if (!foundMatch) return;
    try {
      let video = document.getElementById("remoteVideo");
      if (video !== document.pictureInPictureElement)
        video.requestPictureInPicture();
      else document.exitPictureInPicture();
    } catch (error) {
      console.log(`[pip error]: ${error}`);
    }
  }
  function pip() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 60 60"
        fill="#101010"
        className="pip-btn"
        id="pip"
        onClick={handlePip}
      >
        <path d="M60 6H14v7H7v7H0v34h46v-7h7v-7h7V6zM44 52H2V22h5v25h37v5zm7-7H9V15h5v25h37v5zm7-7H16V8h42v30z" />
        <circle cx="34" cy="44" r="1" />
        <circle cx="37" cy="41" r="1" />
        <circle cx="40" cy="44" r="1" />
        <circle cx="46" cy="44" r="1" />
        <circle cx="43" cy="41" r="1" />
        <circle cx="49" cy="41" r="1" />
        <circle cx="28" cy="44" r="1" />
        <circle cx="31" cy="41" r="1" />
        <circle cx="10" cy="44" r="1" />
        <circle cx="10" cy="38" r="1" />
        <circle cx="13" cy="41" r="1" />
        <circle cx="13" cy="35" r="1" />
        <circle cx="16" cy="44" r="1" />
        <circle cx="22" cy="44" r="1" />
        <circle cx="19" cy="41" r="1" />
        <circle cx="25" cy="41" r="1" />
        <circle cx="10" cy="32" r="1" />
        <circle cx="10" cy="26" r="1" />
        <circle cx="13" cy="29" r="1" />
        <circle cx="13" cy="23" r="1" />
        <circle cx="10" cy="20" r="1" />
        <circle cx="13" cy="17" r="1" />
      </svg>
    );
  }
  return (
    <div className="roomContainer">
      <Modal
        displayModal={displayModal}
        displayPanda={displayPanda}
        modalClick={modalClick}
        pandaClick={pandaClick}
        playingVideo={isPlayingVideo}
        setPlaying={setIsPlayingVideo}
        foundMatch={foundMatch}
      ></Modal>
      <img
        alt="pairstudy logo"
        className="smallLogo"
        onClick={endOfSessionButton}
        src={logoPNG}
      />
      {/* https://github.com/facebook/react/issues/6544 */}
      <div className="videoContainer">
        {showGoHomeOrRejoinHTML ? null : studyTime()}
        <div id="localVideoWrap">
          <video id="localVideo" muted autoPlay ref={userVideo}/>
          {/* {showGoHomeOrRejoinHTML ? null : audioControl()}
          {showGoHomeOrRejoinHTML ? null : videoControl()}
          {foundMatch ? screenShare() : null} */}
          {showGoHomeOrRejoinHTML ? retunHomeOrRematch() : null}
          {/* <div className="video_controls_bar">
              <span className="material-icons" onClick={muteAudio} id="mutebtn">mic</span>
              <span className="material-icons" onClick={muteVideo} id="video_mute">videocam</span>
              <span className="material-icons" onClick={shareScreen} id="share_scrn">screen_share</span>
          </div> */}

          <TodoApp email={email} idToken={idToken}></TodoApp>
          <div
            className="localButton"
            id="modal-button"
            onClick={() => setDisplayModal(true)}
          >
            Study Tools
          </div>
          {/* chat */}
          <Chat
            messages={messages}
            newMessage={newMessage}
            handleSendMessage={handleSendMessage}
            setNewMessage={setNewMessage}
          ></Chat>
        </div>

        <div id="remoteVideoWrap">
          <video id="remoteVideo" autoPlay ref={partnerVideo} />
          {foundMatch ? pip() : null}
          {/* <div id="remoteVideoBar">remote Name</div> */}

          {isPomodoroStarted ? null : (
            <>
              <div id="waitingWarning">
                <br></br>
                <h5>Waiting for a student to connect </h5>
                <p>
                  {" "}
                  Be patient like a lion{" "}
                  <a
                    rel="noopener noreferrer"
                    href="https://giphy.com/gifs/columbia-enews-roaree-XZs7QHlUjWwttTnm2J"
                    target="_blank"
                  >
                    <span role="img" aria-labelledby="lion">
                      🦁{" "}
                    </span>
                  </a>
                </p>
              </div>
              
            </>
          )}
          <div className="video_controls_bar">

          <span id="audio_text">Mute/Umute your audio</span>
          <span id="vid_text">Mute turn video /on/off</span>
          <span id="share_text">Share your screen</span>

          <span className="material-icons" onClick={muteAudio} id="mutebtn">mic</span>
          <span className="material-icons" onClick={muteVideo} id="video_mute">videocam</span>
          <span className="material-icons" onClick={shareScreen} id="share_scrn">screen_share</span>
          <span className="material-icons" onClick={fullScreen} id="full_scrn">open_in_full</span>

          </div>
          <div className="Footer">
            {showGoHomeOrRejoinHTML ? null : endSession()}
          </div>
          
        </div>
      </div>

      

      <Beforeunload
        onBeforeunload={() => {
          tellServerLocalClientEndOfSession();
        }}
      />
    </div>
    
  );
};

export default Room;
