import React, { useEffect, useState } from "react";
import Navbar from "../Home/components/Navbar/Navbar.js";
import TodoApp from "../components/TodoApp";
import WeeklyStats from "../components/Stats/WeeklyStats";
import Meeting from "../Home/components/Meetings/Meeting";
import ReactLoading from "react-loading";
import axios from "axios";
import "../Home/components/Meetings/meetings.css";
import "./Feed.css";
import Dialog from "@material-ui/core/Dialog";
import Alert from "@material-ui/lab/Alert";

function Feed({ idToken, signedUser }) {
  const email = signedUser.email;
  const userName = signedUser.displayName;
  const imageUrl = signedUser.photoURL;
  const [scheduledMeetings, setScheduledMeetings] = useState(null);
  const [displayItems, setDisplayItems] = useState(null);
  const [feedTitle, setFeedTitle] = useState("Discover Sessions");
  const [openDialog, setOpenDialog] = useState(false);
  const [iconSelected, setIconSelected] = useState("explore");

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    setTimeout(fetchMeetings, 0);
  }, []);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  function handleCancelClick() {
    handleDialogOpen();
  }

  function showSession(session) {
    return (
      !isPast(session) &&
      !session["lazilyDeleted"] &&
      session.sessionOwner !== email &&
      session.participants.find((el) => el === email) !== email
    );
  }
  const updateScheduledMeetings = (newItems) => {
    let newDisplayItems = [];
    for (let key in newItems) {
      if (!newItems[key] || Object.keys(newItems[key]).length == 0) {
        continue;
      }
      if (showSession(newItems[key])) {
        newDisplayItems.push(newItems[key]);
      }
    }

    newDisplayItems.sort((a, b) => a["startTimeStamp"] - b["startTimeStamp"]);
    setDisplayItems(newDisplayItems);
    forceUpdate();
  };

  function isPast(meeting) {
    return meeting.endTimeStamp < Date.now();
  }

  const handleSignUp = async (id) => {
    const idx = scheduledMeetings.findIndex((el) => el.id === id);
    if (idx == -1) return;
    scheduledMeetings[idx].participants.push(email);
    axios.post("/api/users/signUpForSessions", {
      idToken,
      session: scheduledMeetings[idx],
    });
    updateScheduledMeetings(scheduledMeetings);
    handleDialogOpen();
  };

  const fetchMeetings = async () => {
    const res = await axios.post("/getAllScheduledSessions", {
      idToken,
    });
    let meetings = res.data;

    setScheduledMeetings(meetings ? meetings : {});
    updateScheduledMeetings(meetings);
  };

  return (
    <div className="top-home-container">
      <Navbar
        email={email}
        imageUrl={imageUrl}
        setIconSelected={setIconSelected}
        iconSelected={iconSelected}
      />
      <div className="homeContainer">
        <div className="leftColumn">
          {/* <div className="joinRoomCard">
              <div className="roomCardTitle">Join a room</div>
              <div>
                <div className="roomCardBody">
                  <input
                    id="remoteRoomIDInput"
                    onChange={(e) => setRemoteNumber(e.target.value)}
                    placeholder="Insert a room id:"
                    onKeyDown={handleKeyDown}
                  ></input>
                  <div
                    className="cardButton"
                    onClick={() => joinARoom(remoteNumber)}
                  >
                    <span>Join</span>
                  </div>
                </div>
                <p className="warning">{joinRemoteRoomWarning}</p>
              </div>
            </div> */}

          {/* <div className="joinRoomCard">
              <h3 className="roomCardTitle"> Your room ID</h3>
              <div className="roomCardBody">
                <div className="remoteRoomIDInput myRoomId">
                  {personalRoomId}
                </div>
                <CopyToClipboard className="cardButton" text={personalRoomId}>
                  <div>
                    <span>Copy</span>
                  </div>
                </CopyToClipboard>
              </div>
              <p className="warning">{joinMyRoomWarning}</p>
              <div
                className="cardButton joinPersonalRoomButton"
                onClick={() => joinMyRoom(personalRoomId)}
              >
                <span>Enter room</span>
              </div>
            </div> */}
          <div className="todoContainer joinRoomCard">
            <TodoApp idToken={idToken}></TodoApp>
          </div>
          <div className="statsCard joinRoomCard ">
            <h3 className="roomCardTitle">Your stats</h3>
            <WeeklyStats email={email} idToken={idToken}></WeeklyStats>
          </div>
        </div>
        <div id="room1" className="rightColumn roomCard">
          {scheduledMeetings && displayItems ? (
            <>
              <div className="feed-title">
                {displayItems.length > 0 && feedTitle}
              </div>
              <div className="meetings">
                <div className="meeting-list">
                  {displayItems.map((meeting) => (
                    <Meeting
                      key={meeting.id}
                      idToken={idToken}
                      meetingObj={meeting}
                      user={{ email }}
                      feedMode={true}
                      handleSignUp={handleSignUp}
                    />
                  ))}
                  {displayItems.length == 0 && (
                    <div className="no-meeting-text">
                      There are currently no scheduled sessions available :(
                      <br />
                      Please check back later &#128521;
                    </div>
                  )}
                </div>
              </div>
              <Dialog
                open={openDialog}
                keepMounted
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <Alert severity="success">
                  You are now signed up for the study session!
                </Alert>
              </Dialog>
            </>
          ) : (
            <div className="meetings">
              <ReactLoading
                type={"spinningBubbles"}
                color={"#dbecf7"}
                height={"100px"}
                width={"100px"}
              />
            </div>
          )}
        </div>
        <a
          id="feedbackLink"
          href="https://bit.ly/3ecnizv"
          target="_blank"
          rel="noopener noreferrer"
        >
          Interested in making PairStudy better?&nbsp; Give us some feedback
          here →
        </a>
      </div>
    </div>
  );
}

export default Feed;
