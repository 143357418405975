import React, { useState, useEffect } from "react";
import Participants from "./Participants";
import TextField from "@material-ui/core/TextField";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import Alert from "@material-ui/lab/Alert";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

// const CssTextField = withStyles({
//   root: {
//     "& label.Mui-focused": {
//       color: "#99D6B1",
//     },
//     "& .MuiInput-underline:after": {
//       borderBottomColor: "#99D6B1",
//     },
//   },
// })(TextField);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MeetingScheduler({
  userName,
  userEmail,
  displayForm,
  setDisplayForm,
  meetingObj,
  editMeetingTrue,
  editMeetingHandler,
  cancelEditMeetingHandler,
  submitFormHandler,
}) {
  const defaultTitle = `${userName.split(" ")[0]}'s study session`;
  const [title, setTitle] = useState(defaultTitle);
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(Date.now());
  const [startTime, setStartTime] = useState(Date.now());
  const [endTime, setEndTime] = useState("");
  const [durationHours, setDurationHours] = useState(1);
  const [durationMinutes, setDurationMinutes] = useState(0);
  const durationHoursList = getDurationList(0, 24, 1);
  const durationMinutesList = getDurationList(0, 45, 15);
  const [className, setClassName] = useState("");
  const [visibility, setVisibility] = useState("public");
  const [participantList, setParticipantList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  function handleCancelClick() {
    handleDialogOpen();
  }

  useEffect(() => {
    if (editMeetingTrue) {
      setTitle(meetingObj.title);
      setDescription(meetingObj.description);
      setDate(new Date(meetingObj.startTimeStamp));
      setStartTime(new Date(meetingObj.startTimeStamp));
      setDurationHours(meetingObj.durationHours);
      setDurationMinutes(meetingObj.durationMinutes);
      setClassName(meetingObj.className);
      setVisibility(meetingObj.visibility);
      setParticipantList(meetingObj.participants);
    }
  }, [editMeetingTrue]);
  function getStyle() {
    return {
      display: displayForm ? "block" : "none",
    };
  }

  function resetFormData() {
    setTitle(defaultTitle);
    setDescription("");
    setDate(Date.now());
    setStartTime(Date.now());
    setDurationHours(1);
    setDurationMinutes(0);
    setClassName("");
    setVisibility("public");
    setParticipantList([]);
  }

  function getDate(rawDate) {
    const todaysDate = new Date(rawDate);
    const year = todaysDate.getFullYear();
    const month = todaysDate.getMonth() + 1;
    const day = todaysDate.getDate();
    const res = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;

    return res;
  }

  function getTime(rawDate) {
    const todaysDate = new Date(rawDate);
    const hour = todaysDate.getHours();
    const minute = todaysDate.getMinutes();
    const res = `${hour < 10 ? "0" + hour : hour}:${
      minute < 10 ? "0" + minute : minute
    }`;
    return res;
  }

  function getDurationList(start, end, step) {
    const res = [];
    for (let i = start; i <= end; i += step) {
      res.push(i);
    }
    return res;
  }

  function cancelMeetingScheduler(event) {
    resetFormData();
    setDisplayForm(false);
    if (editMeetingTrue) {
      cancelEditMeetingHandler();
    }
  }

  function parseMeetingDetails() {
    return {
      title,
      description,
      date: getDate(date),
      startTime: getTime(startTime),
      durationHours,
      durationMinutes,
      className,
      visibility,
      participants: participantList,
      startTimeStamp: getStartTimeStamp(),
      endTimeStamp: getEndTimeStamp(),
    };
  }

  function getStartTimeStamp() {
    const sDate = new Date(`${getDate(date)}T${getTime(startTime)}:00`);

    return sDate.valueOf();
  }

  function getEndTimeStamp() {
    var start = getStartTimeStamp();
    var hoursPast = durationHours * 60 * 60 * 1000;
    var minsPast = durationMinutes * 60 * 1000;
    return start + hoursPast + minsPast;
  }

  function invalidateMeetingDetails(meetingObj) {
    if (meetingObj.title == "") {
      return [true, "Please provide a title for your study session."];
    }

    if (meetingObj.date == "") {
      return [true, "Please provide a valid date  for your study session."];
    }

    if (meetingObj.startTime == "") {
      return [
        true,
        "Please provide a valid start time for your study session.",
      ];
    }

    // Make sure endTIme(+date) is greater than Date.now()
    if (meetingObj.endTimeStamp <= Date.now()) {
      return [
        true,
        "Please provide a start time and duration for your study session so that the end time is later than the current time.",
      ];
    }

    return [false, ""];
  }

  function handleSubmit(event, editMeetingIsTrue) {
    let meetingObject = parseMeetingDetails();
    let [err, msg] = invalidateMeetingDetails(meetingObject);
    if (err) {
      setErrorMessage(msg);
      handleDialogOpen();
      return;
    }
    resetFormData();
    event.preventDefault();

    editMeetingIsTrue
      ? editMeetingHandler(meetingObject)
      : submitFormHandler(meetingObject);

    setDisplayForm(false);
  }

  return (
    <div id="myModal" className="modal" style={getStyle()}>
      <div className="modal-content">
        <div className="modal-header">
          <h3>Schedule study session</h3>
        </div>
        <div className="modal-body" id="popup-text">
          <form className="meeting-form">
            <div>
              <TextField
                color="#99D6B1"
                id="meeting-scheduler-title"
                label="Session Title*"
                inputProps={{
                  maxlength: 50,
                }}
                value={title}
                helperText={`${title.length}/${50}`}
                onChange={(event) => setTitle(event.target.value)}
              />
              <br />
              <br />
            </div>
            <div>
              <TextField
                color="#99D6B1"
                id="meeting-scheduler-description"
                label="Description"
                inputProps={{
                  maxlength: 300,
                }}
                helperText={`${description.length}/${300}`}
                multiline
                value={description}
                placeholder="(optional)"
                maxlength="300"
                onChange={(event) => setDescription(event.target.value)}
              />
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Date"
                  format="MM/dd/yyyy"
                  value={date}
                  onChange={(date) => {
                    setDate(Date.parse(date));
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />

                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Start Time"
                  value={new Date(startTime)}
                  onChange={(time) => {
                    setStartTime(Date.parse(time));
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </div>
            </MuiPickersUtilsProvider>
            <div>
              <br />
              &nbsp;&nbsp;
              <TextField
                id="meeting-scheduler-duration"
                color="#99D6B1"
                select
                label="Duration"
                value={durationHours}
                onChange={(event) => setDurationHours(event.target.value)}
                SelectProps={{
                  native: true,
                }}
              >
                {durationHoursList.map((item) => (
                  <option value={item}>{`${item} hours`}</option>
                ))}
              </TextField>
              <TextField
                id="meeting-scheduler-duration-min"
                color="#99D6B1"
                label="   "
                select
                value={durationMinutes}
                onChange={(event) => setDurationMinutes(event.target.value)}
                SelectProps={{
                  native: true,
                }}
              >
                {durationMinutesList.map((item) => (
                  <option value={item}>{`${item} minutes`}</option>
                ))}
              </TextField>
              <br />
              <br />
              <br />
            </div>
            {/* <div>
              <br /> <label for="classes">Class (optional)</label>
              &nbsp;
              <input
                list="classes-list"
                id="classes"
                value={className}
                onChange={(event) => setClassName(event.target.value)}
              />
              <datalist id="classes-list">
                <option>Data Structures</option>
                <option>Intro to Java</option>
              </datalist>
            </div> */}
            {/* <div className="visibility-div">
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="visibility"
                  name="visibility"
                  value="Visibility"
                  defaultValue="public"
                  onChange={(event) => setVisibility(event.target.value)}
                >
                  <FormLabel component="legend" style={{ fontStyle: "24px;" }}>
                    Visibility
                  </FormLabel>
                  <FormControlLabel
                    control={<Radio />}
                    color="primary"
                    label="Public"
                    id="public"
                    value="public"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    color="primary"
                    label="Private"
                    id="private"
                    value="private"
                  />
                </RadioGroup>
              </FormControl>
            </div> */}
            {/* <div>
              <Participants
                participantList={participantList}
                setParticipantList={setParticipantList}
                userEmail={userEmail}
              />
            </div> */}
          </form>
        </div>
        <div className="modal-footer">
          <div className="disagree-div">
            <button
              className="action-button disagree-button"
              onClick={cancelMeetingScheduler}
            >
              CANCEL
            </button>
          </div>
          <div className="agree-div">
            <button
              className="action-button agree-button"
              id="user-agree-button"
              onClick={(event) => handleSubmit(event, editMeetingTrue)}
            >
              {editMeetingTrue ? "SAVE" : "SCHEDULE"}
            </button>
          </div>
        </div>
      </div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Dialog>
    </div>
  );
}

export default MeetingScheduler;
