// todo list component
import React, { useState, useEffect } from "react";
import TodoItem from "./TodoItem";
import uuid from "react-uuid";
import axios from "axios";

function TodoApp({ idToken }) {
  const [input, setInput] = useState("");
  const [items, setItems] = useState({});
  const [displayItems, setDisplayItems] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    initTodo();
  }, []);

  const initTodo = async () => {
    const res = await axios.post("/api/users/getTodoItemsData", {
      idToken
    });
    let todoItems = res.data.todoItems;
    setItems(todoItems);
    updateDisplayItems(todoItems);
    // forceUpdate();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      addItem();
    }
  };

  const updateDisplayItems = (newItems) => {
    // update database
    // const sendObj = {
    //   email,
    // };

    axios.post("/api/users/updateTodoItems", { newItems, idToken });
    // axios.post();

    // update ui
    displayItems.length = 0;
    // console.log("items", newItems);
    for (let key in newItems) {
      if (!newItems[key]["lazilyDeleted"]) {
        displayItems.push(newItems[key]);
      }
    }
    displayItems.sort((a, b) => b["timeStamp"] - a["timeStamp"]);
    setDisplayItems(displayItems);
    forceUpdate();
  };

  function addItem(event) {
    // console.log("addItem(event) b4, items:", items);
    if (input !== "") {
      let uid = uuid();
      items[uid] = {
        id: uid,
        item_text: input,
        completed: false,
        lazilyDeleted: false,
        timerTime: 0,
        created: Date.now(),
        timeStamp: Date.now(),
      };
      updateDisplayItems(items);
      setItems(items);
    }
    setInput("");
  }

  function updateTimer(id, timerTime) {
    // console.log("update", id, timerTime, items);
    // console.log("items", items[id]);
    items[id]["timerTime"] = timerTime;
    items[id]["timeStamp"] = Date.now();

    updateDisplayItems(items);
    setItems(items);
    // setInput("");
  }

  // lazy deletion
  function removeItem(id) {
    items[id]["lazilyDeleted"] = true;
    updateDisplayItems(items);
  }

  function item_completed(id) {
    if (id === undefined) return;
    items[id]["completed"] = !items[id]["completed"];
  }

  return (
    <div className="todolist">
      <div className="roomCardTitle heading">Your To-Dos</div>
      <div className="todoInput">
        <input
          type="text"
          value={input}
          placeholder="Add a to-do"
          onChange={(event) => {
            setInput(event.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
        <button onClick={addItem}>Add</button>
      </div>

      <div className="items">
        {displayItems.map((item) => (
          <TodoItem
            key={item.id}
            id={item.id}
            item={item.item_text}
            onCheck={removeItem}
            onComplete={item_completed}
            completed={item.completed}
            time={item.timerTime}
            updateTimer={updateTimer}
          />
        ))}
      </div>
    </div>
  );
}

export default TodoApp;
