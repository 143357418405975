import React, { useEffect } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import ReactPlayer from "react-player";
import "./Modal.css";
import Panda from "./Panda";

function Modal({
  displayModal,
  displayPanda,
  modalClick,
  pandaClick,
  playingVideo,
  setPlaying,
  foundMatch,
}) {
  function getStyle() {
    return {
      display: displayModal ? "block" : "none",
    };
  }
  function getYoutubeStyle() {
    return {
      display: displayPanda ? "none" : "block",
    };
  }

  function getPandaStyle() {
    return {
      display: displayPanda ? "block" : "none",
    };
  }

  useEffect(() => {
    const timer = setTimeout(modalClick, 1000);
    // console.log("playingVideo", playingVideo);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div id="myModal" className="modal" style={getStyle()}>
      <div className="modal-content">
        <div className="modal-header">
          <Loader type="Puff" color="#00BFFF" height={40} width={40} />
          {foundMatch ? <h2>Music</h2> : <h2>Finding a match </h2>}
          <Loader type="Puff" color="#00BFFF" height={40} width={40} />
        </div>
        <div className="modal-body" id="popup-text">
          {foundMatch ? null : (
            <>
              {displayPanda ? (
                <h2>Study with Panda while waiting for a match... </h2>
              ) : (
                <h2>Listen to study music while waiting... </h2>
              )}
            </>
          )}

          <div className="modal-row">
            <div id="youtube" style={getYoutubeStyle()}>
              {/* url="url='https://www.youtube.com/watch?v=-FlxM_0S2lA'" */}
              <ReactPlayer
                className="youtube"
                width="300px"
                height="200px"
                url="https://soundcloud.com/katie-brady-518166452/what-do-you-look-for-in-man"
                playing={playingVideo}
                onPlay={() => setPlaying(true)}
                onPause={() => setPlaying(false)}
              ></ReactPlayer>
            </div>

            <div className="panda-div" style={getPandaStyle()}>
              {/* <h6>Panda cares about your study</h6> */}
              <Panda></Panda>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="disagree-div">
            <button
              className="action-button disagree-button"
              onClick={pandaClick}
            >
              {displayPanda ? "🎵 MUSIC 🎵" : "🐼 PANDA 🐼"}
            </button>
          </div>
          <div className="agree-div">
            <button
              className="action-button agree-button"
              id="user-agree-button"
              onClick={modalClick}
            >
              HIDE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Modal;
