import React, { useState } from "react";
import "./Navbar.css";
import logoPNG from "../../assets/psLogo.png";
import DownArrow from "../../assets/DownArrow.svg";
import HomeIcon from "@material-ui/icons/Home";
import ExploreIcon from "@material-ui/icons/Explore";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { FirebaseContext } from "../../../../auth/Firebase";
import { Link, useHistory } from "react-router-dom";
import MyPopover from "../../../components/MyPopover";

function Navbar({ email, imageUrl, iconSelected, setIconSelected }) {
  const [popoverComponent, setPopoverComponent] = useState(null);
  const [anchorTarget, setAnchorTarget] = useState(null);

  const signOutComponent = (
    <div className="simple-popover">
      <div>
        <img
          width="30"
          height="30"
          className="profileImage popover-img"
          src={imageUrl}
        />
        <span className="popover-email">{" " + email}</span>
      </div>
      <FirebaseContext.Consumer>
        {(firebase) => {
          return (
            <div className="sign-out-text" onClick={() => firebase.doSignOut()}>
              Sign Out
            </div>
          );
        }}
      </FirebaseContext.Consumer>
    </div>
  );
  const notificationComponent = (
    <div className="notification-tab">
      Your notification box is empty &#127775;
    </div>
  );

  function getStyle(icon) {
    if (icon === iconSelected) {
      return {
        color: "#0073d0",
      };
    }

    return {
      color: "black",
    };
  }
  return (
    <nav>
      <Link
        to={{
          pathname: "/home",
        }}
      >
        <div className="logo-div">
          <img
            src={logoPNG}
            width="35"
            height="30"
            className="navbar-logo"
            alt="logo"
          />
          {/* <span>PairStudy</span> */}
        </div>
      </Link>
      <div className="center-div">
        <div className="icon-container">
          {/* <HomeIcon
            className="home-icon"
            style={{ color: "#58a6e6", fontSize: "30px" }}
          /> */}
        </div>
      </div>
      <div className="right-div">
        <Link to={{ pathname: "/home" }}>
          <IconButton
            aria-controls="options-menu"
            aria-haspopup="true"
            onClick={() => setIconSelected("home")}
          >
            <HomeIcon
              className="home-icon"
              style={getStyle("home")}
              size="large"
            />
          </IconButton>
        </Link>
        <Link to={{ pathname: "/feed" }}>
          <IconButton
            aria-controls="options-menu"
            aria-haspopup="true"
            onClick={() => setIconSelected("explore")}
          >
            <ExploreIcon
              className="explore-icon"
              style={getStyle("explore")}
              size="large"
            />
          </IconButton>
        </Link>

        <IconButton
          aria-controls="options-menu"
          aria-haspopup="true"
          onClick={(event) => {
            setPopoverComponent(notificationComponent);
            setAnchorTarget(event.currentTarget);
          }}
        >
          <NotificationsIcon style={{ color: "black" }} size="large" />
        </IconButton>
        <div
          className="user-div"
          onClick={(event) => {
            setPopoverComponent(signOutComponent);
            setAnchorTarget(event.currentTarget);
          }}
        >
          <img width="40" height="40" className="profileImage" src={imageUrl} />
          <img
            width="20"
            height="20"
            className="arrow-down"
            alt="profile"
            src={DownArrow}
          />
        </div>
        <MyPopover component={popoverComponent} anchorTarget={anchorTarget} />
      </div>
    </nav>
  );
}

export default Navbar;
