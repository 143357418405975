import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import axios from "axios";
import Room from "./routes/Room";
import GroupRoom from "./routes/Grouproom";
import Home from "./routes/Home";
import LandingPage from "./routes/LandingPage";
import { withFirebase } from "./auth/Firebase";
import "./App.scss";
import Loading from "./routes/components/Loading";
import FAQ from "./routes/FAQ";
import Feed from "./routes/Feed";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.setSignIn = this.setSignIn.bind(this);

    this.state = {
      appLoading: true,
      idToken: null,
      user: null,
    };
  }

  componentDidMount() {
    let app = this;
    this.props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        authUser.getIdToken().then(function (token) {
          if (app.verifyToken(authUser, token)) {
            app.setState({ appLoading: false, idToken: token, user: authUser });
            return;
          }
        });
      }
      if (authUser === null) {
        this.setState({ appLoading: false, idToken: null, user: null });
      }
      return;
    });
  }

  async verifyToken(user, token) {
    let obj = {
      email: user.email,
      userName: user.displayName,
      imageUrl: user.photoURL,
      idToken: token,
    };

    let res = await axios.post("/api/users/login", obj);

    return true;
  }

  setSignIn(user) {
    var app = this;
    if (user) {
      user.getIdToken().then(function (token) {
        if (app.verifyToken(token)) {
          app.setState({ appLoading: false, idToken: token, user: user });
          return;
        }
      });
    }
  }

  componentHelper(props, Component) {
    return (
      <Component
        props={props}
        idToken={this.state.idToken}
        signedUser={this.state.user}
      />
    );
  }

  ifUserSignedIn(Component) {
    if (this.state.appLoading) {
      return <Loading />;
    }

    return this.state.user ? (
      <Component idToken={this.state.idToken} signedUser={this.state.user} />
    ) : (
      // <Login signInSignal={this.setSignIn} />
      <LandingPage signInSignal={this.setSignIn} />
    );
  }

  loadRoom(Component) {
    return <Component />;
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={() => this.ifUserSignedIn(Home)} />
            <Route path="/home" render={() => this.ifUserSignedIn(Home)} />
            <Route path="/feed" render={() => this.ifUserSignedIn(Feed)} />
            <Route
              path="/room/:roomType/:number"
              component={(props) => this.componentHelper(props, Room)}
            />
            <Route
              path="/virtualRoom"
              component={(props) => this.componentHelper(props, GroupRoom)}
            />
            <Route
              path="/faq"
              component={(props) => this.componentHelper(props, FAQ)}
            />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default withFirebase(App);
