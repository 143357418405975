import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import uuid from "react-uuid";
import axios from "axios";
import Meeting from "./Meeting.js";
import MeetingScheduler from "./MeetingScheduler";
import "../../../components/Modal.css";
import "./meetings.css";
import "../../mobile.css";

function Meetings({ idToken, user }) {
  const [scheduledSessions, setScheduledSessions] = useState(null);
  const [displayItems, setDisplayItems] = useState(null);
  const [displayForm, setDisplayForm] = useState(false);
  const [editMeetingObj, setEditMeetingObj] = useState(null);
  const [editMeetingTrue, setEditMeetingTrue] = useState(false);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    fetchMeetings();
  }, []);

  const fetchMeetings = async () => {
    const res = await axios.post("/api/users/getScheduledSessions", {
      idToken,
    });
    let sessions = res.data;

    setMyScheduledSessions(sessions);
    updateScheduledMeetings(sessions);
  };
  function setMyScheduledSessions(sessions) {
    let newObj = {};
    if (sessions) {
      for (let i = 0; i < sessions.length; i++) {
        newObj[sessions[i].id] = sessions[i];
      }
    }
    setScheduledSessions(newObj);
  }

  const updateScheduledMeetings = (newItems) => {
    const userIsParticipant = (meetingObj) =>
      meetingObj.participants.find((eachEmail) => eachEmail === user.email) ===
      user.email;

    let newDisplayItems = [];
    for (let key in newItems) {
      if (!newItems[key] || Object.keys(newItems[key]).length == 0) {
        continue;
      }
      if (isPast(newItems[key]) || newItems[key]["lazilyDeleted"]) {
        continue;
      }
      if (
        !userIsParticipant(newItems[key]) &&
        !(newItems[key].sessionOwner == user.email)
      ) {
        continue;
      }

      newDisplayItems.push(newItems[key]);
    }

    newDisplayItems.sort((a, b) => a["startTimeStamp"] - b["startTimeStamp"]);
    setDisplayItems(newDisplayItems);
    forceUpdate();
  };

  function isPast(meeting) {
    return meeting.endTimeStamp < Date.now();
  }

  function addMeeting(meetingForm) {
    let uid = uuid();
    let password = uuid();
    scheduledSessions[uid] = {
      id: uid,
      password: password,
      sessionOwner: user.email,
      title: meetingForm.title,
      description: meetingForm.description,
      className: meetingForm.className,
      date: meetingForm.date,
      startTime: meetingForm.startTime,
      durationHours: meetingForm.durationHours,
      durationMinutes: meetingForm.durationMinutes,
      startTimeStamp: meetingForm.startTimeStamp,
      endTimeStamp: meetingForm.endTimeStamp,
      visibility: meetingForm.visibility,
      participants: meetingForm.participants,
      lazilyDeleted: false,
    };
    axios.post("/api/users/updateScheduledSessions", {
      idToken,
      session: scheduledSessions[uid],
    });
    updateScheduledMeetings(scheduledSessions);
    setScheduledSessions(scheduledSessions);
  }

  function submitEditedMeeting(meetingForm) {
    scheduledSessions[editMeetingObj.id] = {
      id: editMeetingObj.id,
      password: editMeetingObj.password,
      sessionOwner: user.email,
      title: meetingForm.title,
      description: meetingForm.description,
      className: meetingForm.className,
      date: meetingForm.date,
      startTime: meetingForm.startTime,
      durationHours: meetingForm.durationHours,
      durationMinutes: meetingForm.durationMinutes,
      startTimeStamp: meetingForm.startTimeStamp,
      endTimeStamp: meetingForm.endTimeStamp,
      visibility: meetingForm.visibility,
      participants: meetingForm.participants,
      lazilyDeleted: editMeetingObj.lazilyDeleted,
    };
    axios.post("/api/users/updateScheduledSessions", {
      idToken,
      session: scheduledSessions[editMeetingObj.id],
    });
    updateScheduledMeetings(scheduledSessions);
    setScheduledSessions(scheduledSessions);
    cancelEditMeeting();
  }

  function editMeeting(id) {
    setEditMeetingObj(scheduledSessions[id]);
    setEditMeetingTrue(true);
    setDisplayForm(true);
  }

  function cancelEditMeeting() {
    setEditMeetingObj(null);
    setEditMeetingTrue(false);
    setDisplayForm(false);
  }

  function removeMeeting(id) {
    scheduledSessions[id]["lazilyDeleted"] = true;
    axios.post("/api/users/updateScheduledSessions", {
      idToken,
      session: scheduledSessions[id],
    });
    updateScheduledMeetings(scheduledSessions);
  }
  function removeFromMeeting(id) {
    if (!scheduledSessions[id].participants) return;
    scheduledSessions[id].participants = scheduledSessions[
      id
    ].participants.filter((email) => email !== user.email);

    axios.post("/api/users/updateScheduledSessions", {
      idToken,
      session: scheduledSessions[id],
    });
    updateScheduledMeetings(scheduledSessions);
  }
  return (
    <div className="meetings">
      <h2 className="roomCardTitle bottomRowTitle">Your Study Sessions</h2>
      {scheduledSessions && displayItems ? (
        <>
          <div className="meeting-list">
            {displayItems.map((meeting) => (
              <Meeting
                key={meeting.id}
                idToken={idToken}
                meetingObj={meeting}
                user={user}
                editMeeting={editMeeting}
                cancelMeeting={removeMeeting}
                removeFromMeeting={removeFromMeeting}
                feedMode={false}
              />
            ))}
            {displayItems.length == 0 && (
              <div className="no-meeting-text">
                You currently have no scheduled sessions :(
                <br /> Schedule a session below &#x1f447;
              </div>
            )}
          </div>
          <div
            className="schedule-meeting-button cardButton joinPersonalRoomButton"
            onClick={() => setDisplayForm(true)}
          >
            <span>Schedule session</span>
          </div>
          <MeetingScheduler
            userName={user.userName}
            userEmail={user.email}
            displayForm={displayForm}
            setDisplayForm={setDisplayForm}
            meetingObj={editMeetingObj}
            editMeetingTrue={editMeetingTrue}
            editMeetingHandler={submitEditedMeeting}
            cancelEditMeetingHandler={cancelEditMeeting}
            submitFormHandler={addMeeting}
          />
        </>
      ) : (
        <ReactLoading
          type={"spinningBubbles"}
          color={"#dbecf7"}
          height={"50px"}
          width={"50px"}
        />
      )}
    </div>
  );
}

export default Meetings;
