import app from "firebase/app";
import "firebase/auth";
import { config } from "./config.js";

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
  }

  doGoogleSignIn(callback_function) {
    var provider = new app.auth.GoogleAuthProvider();
    this.auth
      .signInWithPopup(provider)
      .then((result) => {
        callback_function(result.user);
      })
      .catch((error) => {
        console.log(error);
        // ...
      });

    return;
  }

  doMsSignIn(callback_function) {
    var provider = new app.auth.OAuthProvider("microsoft.com");
    // provider.addScope('mail.read');
    // provider.setCustomParameters({
    //   prompt: 'consent',
    // });

    this.auth
      .signInWithPopup(provider)
      .then((result) => {
        callback_function(result.user);
      })
      .catch((error) => {
        console.log(error);
      });

    return;
  }

  userAuthListener(authListener) {
    this.auth.onAuthStateChanged(function (user) {
      authListener(user);
    });
  }

  doSignOut() {
    this.auth.signOut();
  }
}

export default Firebase;
