import React from "react";
import "./Chat.css";

function Chat({ messages, newMessage, handleSendMessage, setNewMessage }) {
  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  // need to fix empty string with \n\n\n
  const sendMessage = () => {
    if (newMessage !== "" && newMessage !== "\n") {
      handleSendMessage();
    }
  };

  return (
    <div className="chat-room-container">
      <div className="messages-container">
        <ol className="messages-list">
          {messages.map((message, i) => (
            <li
              key={i}
              className={`message-item ${
                message.ownedByCurrentUser ? "my-message" : "received-message"
              }`}
            >
              {message.body}
            </li>
          ))}
        </ol>
      </div>
      <div className="send-container">
        <textarea
          value={newMessage}
          onChange={handleNewMessageChange}
          placeholder="chat ..."
          className="new-message-input-field"
          onKeyDown={handleKeyDown}
        />
        <button onClick={sendMessage} className="send-message-button">
          ^
        </button>
      </div>
    </div>
  );
}

export default Chat;
